.expenses {
    .items-table td {
        vertical-align: middle;
    }

    .items-table td:not(:nth-of-type(2)),
    .items-table th:not(:nth-of-type(2)) {
        text-align: center;
    }

    .items-table .description {
        color: rgba(0, 0, 0, 0.5);
        font-size: 0.9rem;
        line-height: 0.9rem;
    }
}
